.app-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #282c34;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.header-content h1, .count {
  color: #fff;
}

.body-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.tab-content {
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.session-id {
  font-size: 10px;
}

.event-count {
  margin-right: 8px;
}

.event-header {
  display: flex;
  flex-direction: row;
}

.event-list, .charts, .links {
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:8px;
}

.pagination {
  margin-top: 16px;
}

.reverse {
  display: flex;
}

.reverse > * {
  margin-left: 8px;
}

.chart {
  margin-bottom: 16px;
}

.nav {
  margin: 0 2px;
}

.event-desc {
  margin: 0;
}

.links {
  display: flex;
  flex-direction: column;
  height: 48vh;
  align-items: center;
  padding: 32px;
  justify-content: space-between;
}

.link-item {
  width: 200px;
}

.period-select {
  margin: 8px 0;
}